<template>
  <div :class="show ? 'bj ts' : 'bj'">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <!-- <a class="navbar-brand" href="#">首页</a> -->
      <img src="../assets/img/logo.png" alt="" class="navbar-brand dp c-p logo" @click="shouye()">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse fx" id="navbarNav">
        <ul class="navbar-nav">
          <li :class="rout === '/'? 'nav-item active' : 'nav-item ' ">
            <router-link class="nav-link" to="/">首页 <span class="sr-only">(current)</span></router-link>
          </li>
          <li :class="rout === '/product' || rout === '/productinfo' ? 'nav-item active' : 'nav-item ' ">
            <router-link class="nav-link" to="/product">产品与技术</router-link>
          </li>
          <li :class="rout === '/custom'? 'nav-item active' : 'nav-item ' " @click="kaitong">
            <!-- to="/custom" -->
            <router-link  class="nav-link" to="">客户中心</router-link>
          </li>
          <li :class="rout === '/news' || rout === '/newsinfo'? 'nav-item active' : 'nav-item ' ">
            <!-- to="/news" -->
            <router-link  class="nav-link " to="/news">尘芯新闻</router-link>
          </li>
          <li :class="rout === '/invest'? 'nav-item active' : 'nav-item ' ">
            <router-link  class="nav-link " to="invest">投资者关系</router-link>
          </li>
          <li :class="rout === '/about'? 'nav-item active' : 'nav-item ' ">
            <router-link  class="nav-link " to="/about">关于尘芯</router-link>
          </li>
          <li :class="rout === '/contact'? 'nav-item active' : 'nav-item ' ">
            <router-link  class="nav-link " to="/contact">联系我们</router-link>
          </li>
        </ul>
          <el-input v-model="sc" placeholder="请输入内容" class="inpot"   v-on:keyup.native.13="submit"></el-input>
        <form class="form-inline my-2 my-lg-0">
          <!-- <input class="form-control mr-sm-2 bor" v-model="sc" placeholder="Search" aria-label="Search" @keyup.enter="submit"> -->
          <!-- <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> -->
        </form>
        <p class="c-p zhong" v-if="0">{{yuyan}}
          <ul class="huan">
            <li :class=" yuyan === '中文'? 'active' : '' " @click="huan('zh')">中文</li>
            <li :class=" yuyan === '英文'? 'active' : '' " @click="huan ('en')">英文</li>
          </ul>
        </p>
        <p class="ds" v-if="0"> | </p>
        <p v-if="0">登录</p>
        <p class="zhu" v-if="0">注册</p>
      </div>
    </nav>
  </div>
</template>

<script>
import {get_article} from '@/assets/js/api'
import { MessageBox } from 'element-ui';
export default {
 data() {
  return{
    rout: '/',
    show: false,
    yu: 'zh',
    yuyan: '中文',
    sc: '',
    jishuList: []
  }
 },
 created() {
   let lan = window.localStorage.getItem('lan')
   if (lan === 'en') {
    this.yuyan = '英文'
   } else {
    this.yuyan = '中文'
   }
 },
 methods:{
  kaitong() {
    MessageBox.alert('暂未开放', '', {
        // confirmButtonText: '确认',
        type: 'warning',
        showConfirmButton: false,
        showClose: false,
        center: true
      })

      setTimeout(() => {
        MessageBox.close();
      }, 1500);
  },
  submit(){
    if(this.sc) {
      this.getjishuList()
    }
  },
  shouye(){
    if (this.rout != '/') {
      this.$router.push({
              path: '/',
            })
    }
  },
  async getjishuList() {
      const res = await get_article({'name': this.sc})
      if(res.data.status === 200) {
        // this.jishuList = res.data.result.data
        window.localStorage.setItem('data', JSON.stringify(res.data.result.data))
        if (this.rout != '/search') {
            this.$router.push({
              path: '/search',
            })
          } else {
            this.$bus.$emit('sccc', res.data.result.data)
          }
        }
    },
  huan(v) {
    if (v === 'zh') {
      this.yuyan = '中文'
      window.localStorage.setItem('lan', 'zh')
    } else {
      this.yuyan = '英文'
      window.localStorage.setItem('lan', 'en')
    }
  }
 },
 watch: {
  $route (to, from) {
    this.rout = to.path
    if (to.path === '/info') {
      this.rout = window.localStorage.getItem('lu')
    }
    if (to.path == '/news' || to.path == '/productinfo' || to.path == '/invest' || to.path == '/about' || to.path == '/product') {
      this.show = true
    } else {
      this.show = false
    }
    // console.log(to.path, this.rout)
  }
 }
}
</script>

<style lang="less" scoped>
.logo{
  width: 190px;
}
.inpot{
  width: 240px;
  border-radius: 40px;
}
/deep/.el-input__inner{
  border-radius: 40px;
  height: 50px;
  border: none;
  background: url("../assets/img/sw.png") no-repeat;
  background-size: 100% 100%;
}
.bj{
  height: 56px;
  width: 100%;
  // background: rgba(255, 255, 255,0);
  position: fixed;
  z-index: 1000;
  // line-height: 60px;
}
p{
    margin-bottom: 0px;
    // text-align: center;
    line-height: 56px;
    padding: 0 1rem;
  }
  .zhong{
    position: relative;
    &:hover{
    .huan{
      display: block;
    }
    }
    .huan{
      display: none;
      position: absolute;
      top: 50px;
      left: 10px;
      background: #fff;
      li{
        padding: 0 10px;
        &:hover{
          color: #007fff;
        }
      }
        .active{
          background: #007fff;
          color: #fff;
          &:hover{
          color: #fff;
        }
        }
    }
  }
@media (max-width: 1260px){
  .ts{
  position: relative !important;
}
  .dp{
    // display: none;
  }
  p{
    margin-bottom: 0px;
    // text-align: center;
    line-height: 56px;
    padding: 0 0.5rem;
  }
}
@media (min-width: 992px){
  .ts{
  position: relative !important;
}
  .bj{
  height: 56px;
  width: 100%;
  background: rgba(255, 255, 255,0.5);
  position: fixed;
  border-bottom: 2px solid #fff;
  // line-height: 60px;
}
  .bg-light{
    background: rgba(255, 255, 255,0.5) !important;
  }
  .fx{
    justify-content: end;
  }
  .zhu{
    color: #fff;
    background: #007fff;
    height: 54px;
    // height: 100%;
  }
  .navbar{
    padding: 0 1rem;
  }
  p{
    margin-bottom: 0px;
    text-align: center;
    line-height: 56px;
    // padding: 0 1rem;
  }
}
@media (max-width: 991px){
  .ts{
    position: fixed !important;
  }
  .ds{
    display: none;
  }
  .dp{
    display: inline-block;
  }
  .navbar-collapse{
    background: #fff;
    padding: 20px;
    border-bottom: 1px solid #f5f5f5;
  }
}
.bor{
  border-radius: 50px;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: #007fff;
}
.navbar{
  height: 56px;
}
</style>