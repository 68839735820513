<template>
  <div class="footer">
    <div class="content">
      <img src="../assets/img/foologo.png" alt="">
      <div class="xia">
        <div>
          <h1>行业解决方案</h1>
          <p @click="Jump(2,58)" class="c-p">无线电子雷管</p>
          <p @click="Jump(2,57)" class="c-p">智能化养殖</p>
          <!-- <p @click="Jump(2,51)" class="c-p">智慧医疗</p>
          <p @click="Jump(2,50)" class="c-p">智慧军工</p> -->
        </div>
        <div>
          <h1 @click="Jump2('/product')" class="c-p">核心产品</h1>
          <p @click="Jump2('/product')" class="c-p">微功耗射频芯片</p>
          <p @click="Jump2('/product')" class="c-p">微功耗计算芯片</p>
          <p @click="Jump2('/product')" class="c-p">微功耗安全芯片</p>
        </div>
        <!-- <div>
          <h1 @click="Jump2('/custom')" class="c-p">客户中心</h1>
          <p @click="Jump2('/custom')" class="c-p">技术文档</p>
          <p @click="Jump2('/custom')" class="c-p">客户培训</p>
          <p @click="Jump2('/custom')" class="c-p">技术案例</p>
        </div> -->
        <div>
          <h1 @click="Jump2('/about')" class="c-p">关于尘芯</h1>
          <p @click="Jump2('/about')" class="c-p">公司介绍</p>
          <p @click="Jump2('/about')" class="c-p">公司发展历程</p>
          <!-- <p @click="Jump2('/about')" class="c-p">专利及认证</p>
          <p @click="Jump2('/about')" class="c-p">合作伙伴</p> -->
        </div>
        <div>
          <h1 @click="Jump2('/contact')" class="c-p">联系我们</h1>
          <p @click="Jump2('/contact')" class="c-p">市场拓展：marketing@micmote.com</p>
          <p @click="Jump2('/contact')" class="c-p">技术支持：support@micmote.com</p>
          <p @click="Jump2('/contact')" class="c-p">媒体联络：PR@micmote.com</p>
        </div>
      </div>
      <div class="xian"></div>
      <div class="ban">
        <p @click="ju" class="c-p">蜀ICP备2023008575号-1</p>
        <div>
          <img class="m-r-10" src="../assets/img/tel.png" alt="">
          <img class="m-r-10" src="../assets/img/wx.png" alt="">
          <img src="../assets/img/yj.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    ju(){
      window.open("https://beian.miit.gov.cn/")
    },
    Jump(cate_id, id) {
      this.$router.push({
        path: '/info',
        query: {
          cate_id: cate_id,
          id: id
        }
      })
    },
    Jump1(id) {
      this.$router.push({
        path: '/productinfo',
        query: {
          id: id
        }
      })
    },
    Jump2(val) {
      if(this.$route.path !== val) {
        this.$router.push({
          path: val,
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .footer{
    // margin-top: 20px;
    background: #000;
    padding: 20px 0;
  }
  .m-r-10{
    margin-right: 10px;
  }
  @media (max-width: 992px){
    .content{
      width: 100%;
      text-align: center;
      .xia{
        margin-top: 30px;
        h1{
          font-size: 14px;
          color: #fff;
        }
        p{
          font-size: 12px;
          color: rgb(206, 206, 206);
          margin-bottom: 10px;
        }
      }
      .xian{
        height: 1px;
        background: #fff;
        width: 100%;
      }
      .ban{
        color: #fff;
        font-size: 12px;
        margin-top: 10px;
        align-items: center;
      }
    }
  }
  @media (min-width: 992px){
    .content{
      width: 990px;
      margin: 0 auto;
      .xia{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        h1{
          font-size: 14px;
          color: #fff;
          margin-bottom: 20px;
        }
        p{
          font-size: 12px;
          color: rgb(206, 206, 206);
          margin-bottom: 10px;
        }
      }
      .xian{
        height: 1px;
        background: #fff;
        width: 100%;
      }
      .ban{
        display: flex;
        justify-content: space-between;
        color: #fff;
        font-size: 12px;
        margin-top: 10px;
        align-items: center;
      }
    }
  }
  @media (min-width: 1200px){
    .content{
      width: 1200px;
      margin: 0 auto;
      .xia{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        h1{
          font-size: 14px;
          color: #fff;
          margin-bottom: 20px;
        }
        p{
          font-size: 12px;
          color: rgb(206, 206, 206);
          margin-bottom: 10px;
        }
      }
      .xian{
        height: 1px;
        background: #fff;
        width: 100%;
      }
      .ban{
        display: flex;
        justify-content: space-between;
        color: #fff;
        font-size: 12px;
        margin-top: 10px;
        align-items: center;
      }
    }
  }
</style>