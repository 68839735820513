import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
// import 'bootstrap'

// 初始化样式
import './assets/css/reset.css';
// 公共样式
import './assets/css/share.css';
// 引入element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import scrollReveal from "scrollreveal";
// 引入初始css样式
import "animate.css/animate.min.css"
import axios from 'axios'

// 全局注册 $
Vue.prototype.$ = $
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$scrollReveal = scrollReveal();
Vue.prototype.$axios = axios
Vue.prototype.$bus = new Vue();


router.afterEach((to, from) => {
//  window.scrollTo(0,0)
  window.localStorage.setItem('lu', '')
 if (to.path === '/info') {
  window.localStorage.setItem('lu', from.path)
 }
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
