<template>
  <div id="app">
    <Nav></Nav>
    <Content></Content>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from '@/components/nav.vue'
import Content from '@/components/content.vue'
import Footer from '@/components/footer.vue'

export default {
  components: {
    Nav,
    Content,
    Footer
  }
}
</script>

<style lang="less">

</style>
