<template>
  <div class="content">
    <div class="banner">
      <div class="recommendPage">
        <swiper :options="swiperOption" ref="mySwiper" class="swiper-wrapper">
          <swiper-slide v-for="(item, index) in bannerlist" :key="index">
            <img :src="item.path" alt="" class="w100">
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <div class="m-b-40" id="d1">
      <p class="t-c m-t-40 t-15 m-b-40">CORE PRODUCTS | 核心产品</p>
      <div class="fx">
        <div class="core">
          <img src="https://upload.028hongtu.com/%E8%8A%AF%E7%89%87%E7%BA%A7%E4%BA%A7%E5%93%81.jpg" class="w100" alt="">
          <el-button class="core-btn" type="primary" @click="buu(1)">点击查看</el-button>
        </div>
        <div class="core">
          <img src="https://upload.028hongtu.com/%E6%A8%A1%E7%BB%84%E7%BA%A7%E4%BA%A7%E5%93%81.jpg" alt="" class="w100">
          <el-button class="core-btn" type="primary" @click="buu(2)">点击查看</el-button>
        </div>
        <div class="core">
          <img src="https://upload.028hongtu.com/%E8%A1%8C%E4%B8%9A%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.jpg" alt="" class="w100">
          <el-button class="core-btn" type="primary" @click="buu(3)">点击查看</el-button>
        </div>
      </div>
    </div>
    <div class="patent" id="d2">
      <p class="t-c t-15">ADVANSED TECHNOLOGIES ｜专利技术</p>
      <div class="fl">
        <div class="patent-core">
          <p><span class="dazi">12</span>项</p>
          <p>发明专利</p>
        </div>
        <div class="patent-core">
          <p><span class="dazi">20</span>项</p>
          <p>软件著作</p>
        </div>
        <div class="patent-core">
          <p><span class="dazi">9</span>个</p>
          <p>硬件产品</p>
        </div>
      </div>
      <div class="fl">
        <div class="patent-duo">
          <p class="patent-h1">
            <img src="../assets/img/cc.png" alt="" class="w1000">
          </p>
          <div class="item">
            <div class="patent-tu c-p" @click="juuuuuu(1)">
              <img src="../assets/img/patent1.png" alt="">
              <p>基于反向散射的无线总线通信方法</p>
            </div>
            <div class="patent-tu c-p" @click="juuuuuu(1)">
              <img src="../assets/img/patent2.png" alt="">
              <p>⽆线-物联网终端的通信控制方法</p>
            </div>
            <div class="patent-tu c-p" @click="juuuuuu(1)">
              <img src="../assets/img/patent3.png" alt="">
              <p>通过无线电直接访问总线的通信方法</p>
            </div>
            <div class="patent-tu c-p" @click="juuuuuu(1)">
              <img src="../assets/img/patent5.png" alt="">
              <p>用于接收物联网终端信号的信号接收系统</p>
            </div>
            <div class="patent-tu c-p" @click="juuuuuu(1)">
              <img src="../assets/img/patent4.png" alt="">
              <p>终端对收发机发送数据的解调电路</p>
            </div>
            <div class="patent-tu c-p" @click="juuuuuu(1)">
              <img src="../assets/img/patent6.png" alt="">
              <p>物联网终端向网关发送数据的调制电路</p>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div id="d3">
      <div class="mak">
        <img src="../assets/img/mak.png" alt="" class="w100">
        <div class="mak-d">
          <p class="t-c t-15 mak-p">MARKETING | 业务⽅向</p>
          <p class="t-c mak-p">高适应性，多行业通用</p>
        </div>
      </div>
      <div class="yewu">
        <div :class="activ1 === index? ' active item c-p' : 'item c-p' " v-for="(item, index) in yewuiList" :key="index" @mousemove="activ1 = index" v-if="index !==3">
          <h3>{{item.name}}</h3>
          <p v-if="activ1 === index">{{item.info}}</p>
          <!-- <img src="../assets/img/jian.png" alt=""> -->
          <img v-if="index === 0" src="../assets/img/product/ying4.png" alt="" :class="activ1 === index? 'tu' : 'tu op' ">
          <img v-if="index === 1" src="../assets/img/product/ying1.png" alt="" :class="activ1 === index? 'tu' : 'tu op' ">
          <img v-if="index === 2" src="../assets/img/product/ying2.png" alt="" :class="activ1 === index? 'tu' : 'tu op' ">
          <img v-if="index === 3" src="../assets/img/product/ying3.png" alt="" :class="activ1 === index? 'tu' : 'tu op' ">
        </div>
      </div>
    </div>
    <div class="new1" id="d4" v-if="false">
      <div class="new">
        <p class="t-c t-15">NEWS | 尘芯新闻</p>
        <p class="t-c">囊括公司资讯，⾏业新闻</p>
        <div class="fx new dddf">
          <div class="zuo">
            <div class="new-img">
              <img :src="xin.path" alt="" class="w100">
            </div>
            <div class="mod">
              <h1 class="title">{{xin.name}}</h1>
              <p class="text">{{xin.info}}</p>
                <p class="new-btn"><el-button type="primary" @click="Jump(3, xin.id)">查看更多</el-button></p>
            </div>
          </div>
          <div class="you">
            <div v-for="(item, index) in peixunList" :key="index" @click="Jump(3,item.id)" class="c-p item">
              <h1 class="title">{{item.name}}</h1>
              <p class="text">{{item.info}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zhanlue" id="d5">
      <div class="title">
        <h1 class="t-c t-15">COOPERATION | 战略合作伙伴</h1>
        <p>众多优秀企业也选择了尘芯物联</p>
      </div>
      <div class="content">
        <div class="item">
          <img src="../assets/img/logo1.png" alt="" class="w100">
        </div>
        <div class="item">
          <img src="../assets/img/logo2.png" alt="" class="w100">
        </div>
        <!-- <div class="item">
          <img src="../assets/img/logo3.png" alt="" class="w100">
        </div> -->
        <div class="item">
          <img src="../assets/img/logo4.png" alt="" class="w100">
        </div>
        <!-- <div class="item">
          <img src="../assets/img/logo5.png" alt="" class="w100">
        </div>
        <div class="item">
          <img src="../assets/img/logo6.png" alt="" class="w100">
        </div>
        <div class="item">
          <img src="../assets/img/logo7.png" alt="" class="w100">
        </div> -->
        <div class="item">
          <img src="../assets/img/logo8.png" alt="" class="w100">
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import {banner,get_article} from '@/assets/js/api'
export default {
  components: { swiper, swiperSlide },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  data() {
    return{
      swiperOption: {
        loop: false,  // 是否循环轮播
        autoplay: true,  // 是否可以自动轮播
        slidesPerView: 1,  // 可是区域内可展示多少个块
        spaceBetween: 30,  // 块之间间隔距离
        initialSlide:0,  // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      activ1: 0,
      bannerlist: [],
      peixunList: [],
      xin: {},
      yewuiList: []
    }
  },
  mounted() {
      let me = this
      window.setTimeout(() => {
        me.donghu('d1')
        me.donghu('d2')
        me.donghu('d3')
        // me.donghu('d4')
        me.donghu('d5')
      }, 200)
      window.onscroll = function() {
        me.donghu('d1')
        me.donghu('d2')
        me.donghu('d3')
        // me.donghu('d4')
        me.donghu('d5')
      }
  },
  created() {
    this.getBanner()
    this.getpeixunList()
    this.getyewuliList()
  },
   methods: {
  Jump(cate_id, id) {
    this.$router.push({
      path: '/info',
      query: {
        cate_id: cate_id,
        id: id
      }
    })
  },
  juuuuuu() {
    window.localStorage.setItem('gun', '1')
    this.$router.push({
      path: '/about'
    })
  },
  buu(id){
    // if (id === 2) {
      this.$router.push({
        path: '/product',
        query: {
            id: id
          }
      })
    // } else {
    //   this.$router.push({
    //       path: '/productinfo',
    //       query: {
    //         id: id
    //       }
    //     })
    // }
  },
      donghu(dom) {
      let h = document.getElementById(dom).offsetTop 
      let h2 = document.getElementById(dom).offsetHeight
      let h3 = document.documentElement.clientHeight
      let stop = document.documentElement.scrollTop || document.body.scrollTop
      if ((h +  h2 > stop) && h < stop + h3) {
        if ( !(document.getElementById(dom).classList.contains('donghua')) )
        document.getElementById(dom).classList.add("donghua");
      }
    },
    async getBanner() {
      const res = await banner({'cate_id': '1'})
      if(res.data.status === 200) {
        this.bannerlist = res.data.result
      }
    },
    async getpeixunList() {
      let list =[]
      const res = await get_article({'parent_id': '3','category_id': '13'})
      if(res.data.status === 200) {
        list = res.data.result.data
        if(list.length > 0) {
          this.xin = list[list.length - 1]
          this.peixunList = list
          this.peixunList.pop()
        }
      }
    },
    async getyewuliList() {
      const res = await get_article({'parent_id': '8','category_id': '33'})
      if(res.data.status === 200) {
        this.yewuiList = res.data.result.data
      }
    }
   }
}
</script>

<style lang="less" scoped>
.content{
  background: #fff;
}
.core-btn{
  position: absolute;
  left: 20px;
  bottom: 50px;
  z-index: 1;
}
.mak{
  position: relative;
}
.mak-d{
  position: absolute;
  left: 0px;
  width: 100%;
  top: 2rem;
}
.mak-p{
  color: #fff;
  // position: absolute;
  // left: 0px;
  width: 100%;
  // top: 2rem;
}

@media (min-width: 992px){
  .m18{
    width: 18rem;
  }
  .fx{
    display: flex;
    justify-content: center;
  }
  .core{
    position: relative;
    width: 378px;
    height: 190px;
    margin-right: 20px;
  }
  .patent-core{
    width: 378px;
    // height: 190px;
    margin-top: 60px;
    text-align: center;
  }
  .dazi{
  color: #007fff;
  font-size: 6rem;
  }
  .patent{
    background: url("../assets/img/patent.png") no-repeat;
    background-size: 100% 100%;
    padding: 3rem;
  }
  .patent-duo{
    background: rgba(0, 127, 255,0.1);
    border: 1px solid #fff;
    // width: 60rem;
    margin: 0 auto;
    width: 990px;
    margin-top: 1rem;
    padding-bottom: 20px;
    .item{
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
    }
  }
  .patent-h1{
    text-align: center;
    padding: 2rem;
    // background: url("../assets/img/probu-h.png") no-repeat;
    // background-position: 19rem 2.5rem;
  }
  .patent-tu{
    width: 140px;
    text-align: center;
  }
  .yewu{
    background-color: #35405e;
    display: flex;
    justify-content: center;
    .item{
      width: 228px;
      height: 360px;
      border-right: 1px solid #686f96;
      padding: 20px;
      color: #fff;
      position: relative;
      .tu{
        position: absolute;
        bottom: 0;
        right: 10px;
      }
      h3{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      p{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
      }
      &:first-child{
        border-left: 1px solid #686f96;
      }
    }
    .active{
      width: 308px;
      background: #4d526c;
    }
  }
  .new1{
    background: #f5f5f5;
    padding: 40px 0;
  }
  .new{
    width: 990px;
    margin: 0 auto;
    .zuo{
      margin-right: 30px;
      width: 400px;
      .new-img{
        margin-bottom: 30px;
      }
      .title{
        font-size: 18px;
        margin-bottom: 10px;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow:ellipsis;
      }
      .text{
        font-size: 12px;
        color: #7b7b7b;
        line-height: 24px;
      }
    }
    .you{
      width: 430px;
      .item{
        margin-bottom: 40px;
      }
      .title{
        font-size: 16px;
        margin-bottom: 10px;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow:ellipsis;
      }
      .text{
        font-size: 12px;
        color: #7b7b7b;
        line-height: 24px;
      }
    }
  }
   .zhanlue{
    background: #fff;
    padding: 40px 0;
    .title{
      text-align: center;
      h1{
        font-size: 18px;
        font-weight: 600;
      }
      p{
        font-size: 14px;
        font-weight: 300;
      }
    }
    .content{
      width: 992px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item{
        width: 238px;
        margin-bottom: 10px;
      }
    }
  }
}
@media (min-width: 1200px){
  .patent-duo{
    background: rgba(0, 127, 255,0.1);
    border: 1px solid #fff;
    // width: 60rem;
    margin: 0 auto;
    margin-top: 1rem;
    width: 1200px;
    padding-bottom: 20px;
    .item{
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
    }
  }
  .patent-tu{
    width: 140px;
    text-align: center;
    img{
      height: 50px;
    }
    p{
      margin-top: 10px;
      font-size: 14px;
    }
  }
  .patent-h1{
    text-align: center;
    padding: 2rem;
    // background: url("../assets/img/probu-h.png") no-repeat;
    // background-position: 26rem 2.5rem;
  }
  .yewu{
  background-color: #35405e;
  display: flex;
  justify-content: center;
  .item{
    width: 280px;
    height: 360px;
    border-right: 1px solid #686f96;
    padding: 20px;
    color: #fff;
    position: relative;
    .tu{
      position: absolute;
      bottom: 0;
      right: 10px;
    }
    h3{
      font-size: 16px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 20px;
    }
    &:first-child{
      border-left: 1px solid #686f96;
    }
  }
  .active{
    width: 360px;
    background: #4d526c;
  }
  }
  .new1{
    background: #f5f5f5;
    padding: 40px 0;
  }
    .new{
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 20px;
      .dddf{
        margin-top: 20px;
      }
      .zuo{
        margin-right: 30px;
        width: 630px;
        .new-img{
          margin-bottom: 30px;
        }
        .title{
          font-size: 18px;
          margin-bottom: 10px;
          overflow:hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow:ellipsis;
        }
        .text{
          font-size: 12px;
          color: #7b7b7b;
          line-height: 24px;
        }
      }
      .you{
        width: 540px;
        .item{
          margin-bottom: 40px;
        }
        .title{
          font-size: 16px;
          margin-bottom: 10px;
          overflow:hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow:ellipsis;
        }
        .text{
          font-size: 12px;
          color: #7b7b7b;
          line-height: 24px;
        }
      }
    }
  .zhanlue{
    background: #fff;
    padding: 40px 0;
    .title{
      text-align: center;
      h1{
        font-size: 1.5em;
        font-weight: 600;
      }
      p{
        font-size: 14px;
        font-weight: 300;
      }
    }
    .content{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item{
        width: 290px;
        margin-bottom: 10px;
      }
    }
  }
}
@media (max-width: 991px){
  .t-15{
    font-size: 16px;
  }
  .m18{
    width: 18rem;
  }
  .core{
    margin: 20px 20px;
    position: relative;
  }
  .patent-core{
    // width: 5rex;
    // height: 190px;
    margin-right: 2rem;
    text-align: center;
  }
  .dazi{
    color: #007fff;
    font-size: 2rem;
  }
  .patent{
    background: url("../assets/img/patent.png") no-repeat;
    background-size: 100% 100%;
    padding: 1rem;
  }
  .fl{
    display: flex;
    flex-wrap: wrap;
  }
  .w1000{
    // width: 100%;
  }
  .patent-duo{
    background: rgba(0, 127, 255,0.1);
    border: 1px solid #fff;
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
    width: 100%;
    padding-bottom: 20px;
    .item{
      // display: flex;
      // justify-content: space-between;
      padding: 0 20px;
      flex-wrap: wrap;
    }
  }
  .patent-h1{
    text-align: center;
    padding: 2rem 0;
    // background: url("../assets/img/probu-h.png") no-repeat;
    // background-position: 1rem 2rem;
  }
  .patent-tu{
    width: 100%;
    text-align: center;
  }
  .yewu{
  background-color: #35405e;
    width: 100%;
    padding: 20px 20px;
  .item{
    width: 100%;
    height: 360px;
    border-right: 1px solid #686f96;
    padding: 20px;
    color: #fff;
    position: relative;
    .tu{
      position: absolute;
      bottom: 0;
      right: 10px;
    }
    h3{
      font-size: 16px;
      font-weight: 600;
    }
    p{
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
    &:first-child{
      border-left: 1px solid #686f96;
    }
  }
  .active{
    width: 100%;
    background: #4d526c;
  }
}
  .jian{
    display: none;
  }
  .new{
  width: 100%;
  // padding: 0px 20px;
  padding-bottom: 20px;
  .zuo{
    width: 100%;
    .new-img{
      margin-bottom: 30px;
    }
    .mod{
      padding: 0px 20px;
    }
    .new-btn{
      text-align: center;
    }
    .title{
      font-size: 16px;
      margin-bottom: 10px;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow:ellipsis;
    }
    .text{
      font-size: 12px;
      color: #7b7b7b;
      line-height: 24px;
    }
  }
  .you{
    display: none;
    width: 100%;
    padding: 0px 20px;
    .title{
      font-size: 16px;
      margin-bottom: 10px;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow:ellipsis;
    }
    .text{
      font-size: 12px;
      color: #7b7b7b;
      line-height: 24px;
    }
  }
  }
  .zhanlue{
    background: #fff;
    padding: 40px 0;
    .title{
      text-align: center;
      h1{
        font-size: 18px;
        font-weight: 600;
      }
      p{
        font-size: 14px;
        font-weight: 300;
      }
    }
    .content{
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item{
        width: 49%;
        margin-bottom: 10px;
      }
    }
  }
}
.banner{
    .recommendPage{
      position: relative;
      width: 100%;
      margin: 0 auto;
    }
   .recommendPage .swiper-container {
        position: relative;
        width: 100%;
        // height: 200px;
      }
    .recommendPage .swiper-container .swiper-slide {
      width: 100%;
      // line-height: 200px;
      // border:1px solid green;
      color: #000;
      font-size: 16px;
      // text-align: center;
    }
    .swiper{
    --swiper-theme-color: #ff6600;/* 设置Swiper风格 */
    --swiper-navigation-color: #00ff33;/* 单独设置按钮颜色 */
    --swiper-navigation-size: 3000px;/* 设置按钮大小 */
  }
    /* 左侧点击按钮 */
    .recommendPage .swiper-button-prev{
        left: -100px;
        position: absolute;
        background-image: url("../assets/img/custom/swiper-l.png");
        background-size: 100%;
        width: 94px;
        height: 94px;
        z-index: 10;
    }
    /* 右侧点击按钮 */
    .recommendPage .swiper-button-next{
        right: -100px;
         position: absolute;
        background-image: url("../assets/img/custom/swiper-r.png");
        background-size: 100%;
        width: 94px;
        height: 94px;
    }
}
.op{
  opacity: 0.15;
}
</style>