<template>
  <div class="ov">
    <div class="content">
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
@media (max-width: 991px){
  .ov{
    padding-top: 56px;
  }
}
</style>