import axios from "axios";
export const banner = (data = {}) => {
    return axios.get('https://admin.micmote.com/index/api/banner', {
        params: data
    })
}
export const get_article = (data = {}) => {
    return axios.get('https://admin.micmote.com/index/api/get_article', {
        params: data
    })
}
export const article_info = (data = {}) => {
    return axios.get('https://admin.micmote.com/index/api/article_info', {
        params: data
    })
}
export const get_product_info = (data = {}) => {
    return axios.get('https://admin.micmote.com/index/api/get_product_info', {
        params: data
    })
}
export const get_fzlc = (data = {}) => {
    return axios.get('https://admin.micmote.com/index/api/get_fzlc', {
        params: data
    })
}
