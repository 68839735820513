import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'home',
    component: Home
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product.vue')
  },
  {
    path: '/productinfo',
    name: 'productinfo',
    component: () => import('../views/productinfo.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/info.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search.vue')
  },
  {
    path: '/custom',
    name: 'custom',
    component: () => import('../views/custom.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue')
  },
  {
    path: '/newsinfo',
    name: 'newsinfo',
    component: () => import('../views/newsinfo.vue')
  },
  {
    path: '/invest',
    name: 'invest',
    component: () => import('../views/invest.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/about.vue')
  },
  {
    path: '/pdf1',
    name: 'pdf1',
    component: () => import( '../views/pdf/pdf1.vue')
  },
  {
    path: '/pdf2',
    name: 'pdf2',
    component: () => import( '../views/pdf/pdf2.vue')
  },
  {
    path: '/pdf3',
    name: 'pdf3',
    component: () => import( '../views/pdf/pdf3.vue')
  },
  {
    path: '/pdf4',
    name: 'pdf4',
    component: () => import( '../views/pdf/pdf4.vue')
  },
  {
    path: '/pdf5',
    name: 'pdf5',
    component: () => import( '../views/pdf/pdf5.vue')
  },
  {
    path: '/pdf6',
    name: 'pdf6',
    component: () => import( '../views/pdf/pdf6.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( '../views/contact.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
